import {
  Patch,
  applyPatches,
  enablePatches,
  produceWithPatches,
  produce,
  isDraft,
  type Draft,
  type Objectish,
} from 'immer';

export { produce, produceWithPatches, applyPatches, isDraft };
export type { Patch, Draft };

enablePatches();

/**
 * Take an array of Immer patches and compress them down to the smallest amount of patches
 * @link https://medium.com/@dedels/using-immer-to-compress-immer-patches-f382835b6c69
 */
export function compressPatches<Data extends Objectish>({
  baseData,
  patches: allPatches,
}: {
  baseData: Data;
  patches: Patch[][] | Patch[];
}) {
  return produceWithPatches(baseData, (draft) => {
    applyPatches(draft, allPatches.flat());
  });
}

/**
 * Loop over changes to applyPatches in small batches (safer) instead of all at once
 * If there are errors, it will skip that change and continue on
 */
export function applyPatchesSafely<Data extends Objectish>({
  data,
  changes,
  logError = console.error,
  logAppClientData = false,
}: {
  data: Data;
  changes: { patches: Patch[] }[];
  logError?: (msg: unknown, ...optionalMsgs: unknown[]) => void;
  logAppClientData?: boolean;
}): Data {
  return changes.reduce((acc, change, i) => {
    try {
      return applyPatches(acc, change.patches);
    } catch (error) {
      const { LOG_IMMER_APP_CLIENT_DATA } = process.env;
      const metadata =
        logAppClientData || LOG_IMMER_APP_CLIENT_DATA
          ? { data, change }
          : change;
      logError(
        `applyPatchesSafely had issues (and so will skip) with patches in change ${i}: ${
          error instanceof Error ? error.message : ''
        }`,
        metadata,
      );
      return acc;
    }
  }, data);
}
